import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "../styles/components/Nav.css";
import axios from "axios";
import logo from "../assets/logo.png";
import Topnav from "../Components/topnav";
import { FaCrown } from "react-icons/fa6";
import { FaBars } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { IoLanguage } from "react-icons/io5";

import {
  Navbar as ReactstrapNavbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink as ReactstrapNavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarToggler,
  Collapse,
} from "reactstrap";

import { logout } from "../features/user/userSlice";

const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isOpenAvatar, setIsOpenAvatar] = useState(false);
  const [dropdownOpenAvatar, setDropdownOpenAvatar] = useState(false);

  const toggleNavbarAvatar = () => setIsOpenAvatar(!isOpenAvatar);
  const toggleDropdownAvatar = () => setDropdownOpenAvatar(!dropdownOpenAvatar);

  const [showNavbar, setShowNavbar] = useState(false);
  const [CategoriesData, setCategoriesData] = useState([]);

  const [dropdownOpen, setDropdownOpen] = useState(null);
  // const { token } = useSelector((state) => state.user);
  const token = JSON.parse(localStorage.getItem("token"));

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  const toggleDropdown = (categoryName) => {
    if (dropdownOpen === categoryName) {
      setDropdownOpen(null);
    } else {
      setDropdownOpen(categoryName);
    }
  };

  const getAllCategories = async () => {
    try {
      const { data } = await axios.get(
        "https://backend.canursvp.com/api/category"
      );

      setCategoriesData(data?.data);
    } catch (error) {}
  };

  const fetchUpload = async () => {
    try {
      const { data } = await axios.get(
        " https://backend.canursvp.com/api/get-all-cards"
      );
    } catch (error) {}
  };

  const uploadImage = (file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("title", "images");
    // formData.append("text1", JSON.stringify(text1));
    // formData.append("text2", JSON.stringify(text2));

    axios
      .post(`https://backend.canursvp.com/api/images/upload`, formData)
      .then((res) => {
        onSubmit(res?.data?.resp?.url);
      })
      .catch(console.error);
  };

  const onSubmit = async (url) => {
    const text1 = {
      top: 150,
      left: 40,
      title: "",
    };
    const text2 = {
      top: 325,
      left: 110,
      title: "",
    };
    const cardData = {
      imgUrl: url,
      text1,
      text2,
    };

    try {
      const { data } = await axios.post(
        `https://backend.canursvp.com/api/card/add`,
        cardData
      );
      navigate(`/customize/${data?.data?._id}`);
    } catch (error) {
      console.error("Error fetching card data:", error.message);
    }
  };

  useEffect(() => {
    getAllCategories();
  }, []);

  const renderCategories = () => {
    // Ensure CategoriesData is an array
    const categories = CategoriesData || [];

    return categories.map((parent) => {
      const subcategories = parent.subcategories?.map((sub) => (
        <DropdownItem
          key={sub._id}
          className="custom-dropdown-item"
          onClick={() => navigate(`/cards/subcategory/${sub._id}`)}
        >
          {sub.name} {/* Updated to sub.name from the data structure */}
        </DropdownItem>
      ));

      return (
        <Dropdown
          nav
          inNavbar
          key={parent?.categoryName}
          isOpen={dropdownOpen === parent?.categoryName}
          toggle={() => toggleDropdown(parent?.categoryName)}
          onMouseEnter={() => setDropdownOpen(parent?.categoryName)}
          onMouseLeave={() => setDropdownOpen(null)}
        >
          <DropdownToggle
            nav
            caret
            onClick={() => navigate(`/cards/category/${parent._id}`)}
          >
            {parent?.categoryName}
          </DropdownToggle>
          <DropdownMenu
            style={{
              width: "auto",
              minWidth: "fit-content",
              maxWidth: "fit-content",
            }}
            onMouseEnter={() => setDropdownOpen(parent?.categoryName)}
            onMouseLeave={() => setDropdownOpen(null)}
            right
            className="custom-dropdown-menu"
          >
            {subcategories}
          </DropdownMenu>
        </Dropdown>
      );
    });
  };

  // language
  const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("en");

  const toggleLanguageDropdown = () => {
    setLanguageDropdownOpen(!languageDropdownOpen);
  };

  const changeLanguage = (lang) => {
    setSelectedLanguage(lang);
    // You can store it in localStorage or state management if needed
    localStorage.setItem("selectedLanguage", lang);
  };

  return (
    <>
      <Topnav />
      <ReactstrapNavbar
        color="light"
        light
        expand="md"
        className="main-navbar p-0"
      >
        <nav className="main-navbar">
          <div className="nav-container">
            <div className="logo">
              <a href="/">
                <img src={logo}></img>
              </a>
            </div>
            <div className="menu-icon" onClick={handleShowNavbar}>
              <FaBars />
            </div>
            <div
              style={{ display: "flex", flexGrow: 1, justifyContent: "center" }}
            >
              <div className={`nav-elements ${showNavbar && "active"}`}>
                <Nav className="mr-auto" navbar>
                  {renderCategories()}
                </Nav>
                <br />

                {/* appears only on mobile */}
                <div className="signup-mobile d-flex">
                  {token?.authtoken && (
                    <>
                      <NavbarToggler onClick={toggleNavbarAvatar} />
                      <Collapse isOpen={isOpenAvatar} navbar>
                        <Nav className="ml-auto" navbar>
                          <NavItem>
                            <Dropdown
                              nav
                              isOpen={dropdownOpenAvatar}
                              toggle={toggleDropdownAvatar}
                            >
                              <DropdownToggle
                                nav
                                caret
                                className="d-flex align-items-center"
                              >
                                {token?.data?.img ? (
                                  <img
                                    src={token?.data?.img}
                                    width="40"
                                    height="40"
                                    className="rounded-circle"
                                    alt="avatar"
                                  />
                                ) : (
                                  <div
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      backgroundColor: "#007bff", // Fallback background color
                                      borderRadius: "50%", // Circle shape
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      color: "white", // Text color (if needed)
                                      fontSize: "16px", // Placeholder text size
                                    }}
                                  >
                                    {token?.data?.username?.charAt(0)}
                                  </div>
                                )}
                              </DropdownToggle>
                              <DropdownMenu right>
                                <DropdownItem>Profile</DropdownItem>
                                <DropdownItem
                                  onClick={() =>
                                    navigate(`/cards/draft-list/draft`)
                                  }
                                >
                                  Draft List
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => {
                                    dispatch(logout());
                                    navigate("/");
                                  }}
                                >
                                  Log Out
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </NavItem>
                        </Nav>
                      </Collapse>
                    </>
                  )}
                  <NavLink
                    to="/category"
                    className="btn-1 d-flex align-items-center justify-content-around"
                    style={{
                      marginRight: "5%",
                      padding: "5px",
                      width: "140px",
                    }}
                  >
                    <FaCrown /> Go Premium
                  </NavLink>
                  <br />
                  {!token?.authtoken && (
                    <NavLink to="/signup" className="btn-1">
                      Sign up
                    </NavLink>
                  )}
                </div>
              </div>
            </div>
            <div className="language-dropdown mx-2">
              <Dropdown
                isOpen={languageDropdownOpen}
                toggle={toggleLanguageDropdown}
              >
                <DropdownToggle nav caret>
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 512 512"
                    class="lang-icon"
                    height="1.5em"
                    width="1.5em"
                    margin="0"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="m478.33 433.6-90-218a22 22 0 0 0-40.67 0l-90 218a22 22 0 1 0 40.67 16.79L316.66 406h102.67l18.33 44.39A22 22 0 0 0 458 464a22 22 0 0 0 20.32-30.4zM334.83 362 368 281.65 401.17 362zm-66.99-19.08a22 22 0 0 0-4.89-30.7c-.2-.15-15-11.13-36.49-34.73 39.65-53.68 62.11-114.75 71.27-143.49H330a22 22 0 0 0 0-44H214V70a22 22 0 0 0-44 0v20H54a22 22 0 0 0 0 44h197.25c-9.52 26.95-27.05 69.5-53.79 108.36-31.41-41.68-43.08-68.65-43.17-68.87a22 22 0 0 0-40.58 17c.58 1.38 14.55 34.23 52.86 83.93.92 1.19 1.83 2.35 2.74 3.51-39.24 44.35-77.74 71.86-93.85 80.74a22 22 0 1 0 21.07 38.63c2.16-1.18 48.6-26.89 101.63-85.59 22.52 24.08 38 35.44 38.93 36.1a22 22 0 0 0 30.75-4.9z"></path>
                  </svg>
                  {/* <IoLanguage className="lang-icon"/> */}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => changeLanguage("en")}>
                    English
                  </DropdownItem>
                  <DropdownItem onClick={() => changeLanguage("es")}>
                    Español
                  </DropdownItem>
                  <DropdownItem onClick={() => changeLanguage("fr")}>
                    Français
                  </DropdownItem>
                  <DropdownItem onClick={() => changeLanguage("de")}>
                    Deutsch
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
            <div className="signup">
              {token?.authtoken && (
                <>
                  <NavbarToggler onClick={toggleNavbarAvatar} />
                  <Collapse isOpen={isOpenAvatar} navbar>
                    <Nav className="ml-auto" navbar>
                      <NavItem>
                        <Dropdown
                          nav
                          isOpen={dropdownOpenAvatar}
                          toggle={toggleDropdownAvatar}
                        >
                          <DropdownToggle
                            nav
                            caret
                            className="d-flex align-items-center"
                            style={{
                              marginRight: "10px",
                            }}
                          >
                            {token?.data?.img ? (
                              <img
                                src={token?.data?.img}
                                width="40"
                                height="40"
                                className="rounded-circle"
                                alt="avatar"
                              />
                            ) : (
                              <div
                                style={{
                                  width: "40px",
                                  height: "40px",
                                  backgroundColor: "red", // Fallback background color
                                  borderRadius: "50%", // Circle shape
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  color: "white", // Text color (if needed)
                                  fontSize: "16px", // Placeholder text size
                                }}
                              >
                                {token?.data?.username?.charAt(0)}
                              </div>
                            )}
                          </DropdownToggle>
                          <DropdownMenu right>
                            <DropdownItem onClick={() => navigate(`/profile`)}>
                              <a href="/profile">Profile</a>
                            </DropdownItem>
                            <DropdownItem
                              onClick={() =>
                                navigate(`/cards/draft-list/draft`)
                              }
                            >
                              <a href="/cards/draft-list/draft">Draft List</a>
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => {
                                dispatch(logout());
                                navigate("/");
                              }}
                            >
                              Log Out
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </NavItem>
                    </Nav>
                  </Collapse>
                </>
              )}
              {!token?.authtoken && (
                <NavLink
                  to="/signup"
                  className="btn-1"
                  style={{
                    marginRight: "15px",
                  }}
                >
                  Sign up
                </NavLink>
              )}
              {/* <button
                className="btn-1 d-flex align-items-center justify-content-around"
                style={{
                  marginRight: "10px",
                  height: "fit-content"
                }}
              >
                <div className="d-flex position-relative">
                  Upload
                  <input
                    type="file"
                    name="image-uploader"
                    className="position-absolute opacity-0 h-100 w-100 start-0 top-0 btn p-0"
                    accept="image/*"
                    onChange={(e) => uploadImage(e.target.files[0])}
                  />
                </div>
              </button> */}
              <NavLink
                to="/category"
                className="btn-1 d-flex align-items-center justify-content-around"
                style={{
                  marginRight: "5px",
                  height: "fit-content",
                }}
              >
                <FaCrown /> Go Premium
              </NavLink>
            </div>
          </div>
        </nav>
      </ReactstrapNavbar>
    </>
  );
};

export default Navbar;
