import React from "react";
import { Routes, Route, NavLink, Outlet } from "react-router-dom";
import "../styles/pages/profile.css";
import image from '../assets/images/invite3.jpg'



const Favorites = () => (
  <div className="profile-section">
    <h1>Favorites</h1>
    <p>All your saved favorite designs will appear here.</p>
  </div>
);

const SavedDrafts = () => (
  <div className="profile-section">
    <h1>Saved Drafts</h1>
    <p>Resume your work on saved drafts anytime.</p>
    <div className="profile-section-drafts-imgs">
        <div>
            <div><img src={image}></img></div>
            <div><img src={image}></img></div>
        </div>
    </div>
  </div>
);

const MyEvents = () => (
  <div className="profile-section">
    <h1>My Events</h1>
    <p>Manage and track all your event invitations here.</p>
  </div>
);

const Purchases = () => (
  <div className="profile-section">
    <h1>Purchases</h1>
    <p>View your past purchases and invoices here.</p>
  </div>
);

const AccountSettings = () => (
  <div className="profile-section">
    <h1>Account Settings</h1>
    <div className="settings-container">
      <div className="settings-group">
        <h3>Personal Information</h3>
        <label>Name:</label>
        <input type="text" placeholder="John Doe" />
        <label>Email:</label>
        <input type="email" placeholder="john.doe@example.com" />
      </div>

      <div className="settings-group">
        <h3>Password</h3>
        <label>Current Password:</label>
        <input type="password" />
        <label>New Password:</label>
        <input type="password" />
        <label>Confirm New Password:</label>
        <input type="password" />
      </div>

      <div className="settings-group">
        <h3>Email Preferences</h3>
        <label>
          <input type="checkbox" /> Receive promotional emails
        </label>
        <label>
          <input type="checkbox" /> Receive updates and newsletters
        </label>
      </div>

      <button className="btn-1">Save Changes</button>
    </div>
  </div>
);

const ProfileLayout = () => {
  return (
    <div className="profile-container">
      <aside className="sidebar">
        <NavLink to="favorites" className="sidebar-link">Favorites</NavLink>
        <NavLink to="saved" className="sidebar-link">Saved Drafts</NavLink>
        <NavLink to="myevents" className="sidebar-link">My Events</NavLink>
        <NavLink to="purchases" className="sidebar-link">Purchases</NavLink>
        <NavLink to="settings" className="sidebar-link">Account Settings</NavLink>
      </aside>
      <main className="profile-content">
        <Outlet />
      </main>
    </div>
  );
};

const ProfilePage = () => {
  return (
    <Routes>
      <Route path="/" element={<ProfileLayout />}>
        <Route path="favorites" element={<Favorites />} />
        <Route path="saved" element={<SavedDrafts />} />
        <Route path="myevents" element={<MyEvents />} />
        <Route path="purchases" element={<Purchases />} />
        <Route path="settings" element={<AccountSettings />} />
        <Route index element={<AccountSettings />} />
      </Route>
    </Routes>
  );
};

export default ProfilePage;
